import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CalendarAndBookingsView from '../views/CalendarAndBookingsView.vue'
import ServicesView from '../views/ServicesView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardLayout from '@/layouts/DashboardLayout'
import SalesView from '../views/SalesView'
import PromoterAreaView from '../views/PromoterAreaView.vue'
import PromoterInfoView from '../views/PromoterInfoView.vue'
import ProfileView from '../views/ProfileView.vue'
import AffiliateProfileView from '../views/AffiliateProfileView.vue'
import ServicesLayout from '@/layouts/ServicesLayout.vue'
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours'
import VehiclesLayout from '@/layouts/VehiclesLayout.vue'
import VehiclesView from '@/views/VehiclesView.vue'
import store from '@/store';

import superRoutes from './super'

const routes = [
    {
        path: '/dashboard',
        meta: {
            requireUserTypes: ['SUPPLIER', 'SUPER'],
        },
        children: [
            {
                path: '',
                component: DashboardLayout,
                children: [
                    {
                        path: '',
                        name: 'dashboardHome',
                        component: HomeView,
                        meta: {
                            requireRoles: ['super-admin', 'admin', 'manager', 'partner-finance'],
                        },
                    },
                    {
                        path: 'calendar-and-bookings',
                        name: 'calendarAndBookings',
                        component: CalendarAndBookingsView,
                        meta: {
                            requireRoles: ['super-admin', 'admin', 'manager', 'employee'],
                        },
                    },
                    {
                        path: 'services',
                        name: 'services',
                        component: ServicesLayout,
                        redirect: '/dashboard/services/preview',
                        children: [
                            {
                                path: 'new',
                                name: 'serviceFormView',
                                component: () => import('@/views/ServiceFormView.vue')
                            },
                            {
                                path: 'preview',
                                name: 'preview',
                                component: ServicesView,
                                children: [
                                    {
                                        path: '',
                                        name: 'services',
                                        component: () => import('@/views/services/ActiveServicesComponent')
                                    },
                                    {
                                        path: 'inactive',
                                        name: 'inactiveServices',
                                        component: () => import('@/views/services/InactiveServicesComponent')
                                    },
                                    {
                                        path: 'draft',
                                        name: 'DraftServices',
                                        component: () => import('@/views/services/DraftServicesComponent')
                                    },
                                    {
                                        path: 'promoted',
                                        name: 'promotedServices',
                                        component: () => import('@/views/services/ActiveServicesComponent')
                                    },
                                ]
                            },
                            {
                                path: 'edit/:id',
                                name: 'serviceFormViewEdit',
                                component: () => import('@/views/ServiceFormView.vue')
                            },
                        ],
                        meta: {
                            requireRoles: ['super-admin', 'admin', 'manager', 'employee'],
                        },
                    },
                    {
                        path: 'vehicles',
                        name: 'vehicles',
                        component: VehiclesLayout,
                        children: [
                            {
                                path: 'new',
                                name: 'vehicleFormView',
                                component: () => import('@/views/VehicleFormView.vue')
                            },
                            {
                                path: 'preview',
                                name: 'vehiclesPreview',
                                component: VehiclesView,
                                children: [
                                    {
                                        path: '',
                                        name: 'activeVehicles',
                                        component: () => import('@/views/vehicles/ActiveVehiclesComponent')
                                    },
                                    {
                                        path: 'inactive',
                                        name: 'vehiclesInactiveServices',
                                        component: () => import('@/views/vehicles/InactiveVehiclesComponent')
                                    }
                                ]
                            },
                            {
                                path: 'edit/:id',
                                name: 'vehicleFormViewEdit',
                                component: () => import('@/views/VehicleFormView.vue')
                            },

                        ],
                        meta: {
                            requireRoles: ['super-admin', 'admin', 'manager', 'employee'],
                        },
                    },
                    {
                        path: 'sales',
                        name: 'sales',
                        component: SalesView,
                        meta: {
                            requireRoles: ['super-admin', 'admin', 'manager', 'partner-finance'],
                        },
                    },
                    {
                        path: 'promoter-area',
                        children: [
                            {
                                path: '',
                                name: 'promoterArea',
                                component: PromoterAreaView
                            },
                            {
                                path: 'info',
                                name: 'promoterInfo',
                                component: PromoterInfoView
                            }
                        ],
                        meta: {
                            requireRoles: ['super-admin', 'admin'],
                        },
                    },
                    {
                        path: 'profile',
                        name: 'profile',
                        component: ProfileView,
                        children: [
                            {
                                path: '',
                                name: 'about',
                                component: () => import('@/views/profile/AboutComponent'),
                            },
                            {
                                path: 'reviews',
                                name: 'reviews',
                                component: () => import('@/views/profile/ReviewsComponent'),
                            },
                            {
                                path: 'team',
                                name: 'team',
                                component: () => import('@/views/profile/TeamMembersComponent'),
                            },
                            {
                                path: 'team/create',
                                name: 'teamCreate',
                                component: () => import('@/views/profile/TeamMembersFormComponent'),
                            },
                            {
                                path: 'team/edit/:id',
                                name: 'teamEdit',
                                component: () => import('@/views/profile/TeamMembersFormComponent'),
                            },
                            {
                                path: 'settings',
                                name: 'settings',
                                component: () => import('@/views/profile/SettingComponent'),
                            },
                            {
                                path: 'edit',
                                name: 'editPartner',
                                component: () => import('@/views/profile/EditFormComponent.vue')
                            },
                        ]
                    }
                ]
            },
            {
                path: 'login',
                name: 'login',
                component: LoginView,
                redirect: '/'
            },
        ]
    },
    {
        path: '/user-panel',
        meta: {
            requireAuth: true,
        },
        children: [
            {
                path: '',
                component: () => import('@/layouts/Layout1.vue'),
                children: [
                    {
                        path: '',
                        name: 'clientHome',
                        component: () => import('@/layouts/ClientDashboardLayout.vue'),
                        redirect: 'dashboard/bookings',
                        children: [
                            {
                                path: 'bookings',
                                component: () => import('@/views/dashboards/client/BookingsView.vue'),
                                children: [
                                    {
                                        path: '',
                                        name: 'ClientBookings',
                                        meta: { pageTitle: 'Bookings', bookingStatus: 'confirmed' },
                                        component: () => import('@/views/dashboards/client/BookingsListView.vue')
                                    },
                                    {
                                        path: 'previous',
                                        name: 'ClientPreviousBookings',
                                        meta: { pageTitle: 'Bookings', bookingStatus: 'completed' },
                                        component: () => import('@/views/dashboards/client/BookingsListView.vue')
                                    },
                                    {
                                        path: 'canceled',
                                        name: 'ClientCanceledBookings',
                                        meta: { pageTitle: 'Bookings', bookingStatus: 'cancelled' },
                                        component: () => import('@/views/dashboards/client/BookingsListView.vue')
                                    },
                                ]
                            },
                            {
                                path: 'messages/:id?',
                                name: 'ClientMessages',
                                meta: { pageTitle: 'Messages' },
                                component: () => import('@/views/dashboards/client/MessagesView.vue')
                            },
                            {
                                path: 'profile',
                                name: 'ClientProfile',
                                meta: { pageTitle: 'Profile' },
                                component: () => import('@/views/dashboards/client/ProfileView.vue')
                            },
                            {
                                path: 'settings',
                                name: 'ClientSettings',
                                meta: { pageTitle: 'Settings' },
                                component: () => import('@/views/dashboards/client/SettingsView.vue')
                            },
                            {
                                path: 'help',
                                name: 'ClientHelp',
                                meta: { pageTitle: 'Help' },
                                component: () => import('@/views/dashboards/client/HelpView.vue')
                            },
                        ]
                    },
                    {
                        path: 'points-term',
                        name: 'pointsTerm',
                        meta: { pageTitle: 'Points Term' },
                        component: () => import('@/views/dashboards/client/PointsTermView.vue')
                    }
                ],
            },
        ]
    },
    {
        path: '/affiliates',
        meta: {
            requireUserTypes: ['AFFILIATE', 'SUPER'],
            requireAuth: true,
        },
        children: [
            {
                path: '',
                component: DashboardLayout,
                children: [
                    // {
                    //     path: '',
                    //     name: 'affiliatesHome',
                    //     component: HomeView
                    // },
                    // {
                    //     path: 'calendar-and-bookings',
                    //     name: 'calendarAndBookings',
                    //     component: CalendarAndBookingsView
                    // },
                    {
                        path: '',
                        children: [
                            {
                                path: '',
                                name: 'affiliatesHome',
                                component: PromoterAreaView
                            },
                            {
                                path: 'info',
                                name: 'promoterInfo',
                                component: PromoterInfoView
                            },
                        ]
                    },
                    {
                        path: 'dashboard',
                        name: 'affiliatesDash',
                        component: PromoterAreaView
                    },
                    {
                        path: 'profile',
                        name: 'affiliateProfile',
                        component: AffiliateProfileView,
                        children: [
                            {
                                path: '',
                                name: 'aboutAffiliate',
                                component: () => import('@/views/dashboards/affiliate/AboutComponent'),
                            },
                            {
                                path: 'settings',
                                name: 'affiliateSettings',
                                component: () => import('@/views/dashboards/affiliate/SettingComponent'),
                            },
                            {
                                path: 'edit',
                                name: 'editAffiliate',
                                component: () => import('@/views/dashboards/affiliate/EditFormComponent.vue')
                            },
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/Layout1.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/views/Home.vue'),
            },
            {
                path: 'business',
                name: 'business',
                component: () => import('@/views/Business.vue'),
            },
            {
                path: 'affiliate',
                name: 'affiliate',
                component: () => import('@/views/Affiliate.vue'),
            },
            {
                path: 'affiliate-terms',
                name: 'affiliateTerms',
                component: () => import('@/views/AffiliateTermsView.vue'),
            },
            {
                path: 'suggestions',
                name: 'recommendations',
                component: () => import('@/views/Recommendations.vue'),
            },
            {
                path: 'transfers',
                name: 'transfers',
                component: () => import('@/views/Transfers.vue')
            },
            {
                path: 'transfers-quote-request',
                name: 'transferQuoteRequest',
                component: () => import('@/views/TransferQuoteRequest.vue')
            },
            {
                path: 'transfers/offers',
                name: 'transfersOffers',
                component: () => import('@/views/transfersOffers.vue')
            },
            {
                path: 'transfers/offers/:id',
                name: 'transferRoute',
                component: () => import('@/views/TransferView.vue')
            },
            {
                path: 'events',
                name: 'events',
                component: () => import('@/views/EventView.vue')
            },
            {
                path: '/search/:searchString?',
                name: 'searchPage',
                component: () => import('@/views/Search')
            },
            {
                path: 'be-a-partner',
                name: 'beAPartner',
                component: () => import('@/views/BeAPartner.vue')
            },
            {
                path: 'be-an-affiliate',
                name: 'beAffiliate',
                component: () => import('@/views/BeAffiliate.vue')
            },
            {
                path: 'register',
                name: 'userRegister',
                component: () => import('@/views/UserRegister.vue')
            },
            {
                path: 'password/reset',
                name: 'passwordReset',
                component: () => import('@/views/PasswordReset.vue')
            },
            {
                path: 'purchase-complete',
                name: 'purchaseComplete',
                component: () => import('@/views/PurchaseCompleteView.vue')
            },
            {
                path: 'register-complete',
                name: 'registerComplete',
                component: () => import('@/views/RegisterCompleteView.vue')
            },
            {
                path: 'about-us',
                name: 'about-us',
                component: () => import('@/views/AboutView.vue')
            },
            {
                path: 'blog',
                name: 'blog',
                component: () => import('@/views/BlogView.vue')
            },
            {
                path: 'press-kit',
                name: 'press-kit',
                component: () => import('@/views/PressKitView.vue')
            },
            {
                path: 'affiliate-program',
                name: 'affiliate-program',
                component: () => import('@/views/AffiliateProgramView.vue')
            },
            {
                path: 'privacy-policy',
                name: 'privacyPolicy',
                component: () => import('@/views/PrivacyPolicyView.vue')
            },
            {
                path: 'terms-and-conditions',
                name: 'termsConditions',
                component: () => import('@/views/TermsConditionsView.vue')
            },
            {
                path: 'faqs',
                name: 'faqs',
                component: () => import('@/views/FaqView.vue')
            },
            {
                path: 'experiences',
                name: 'experiences',
                component: () => import('@/views/Category.vue')
            },
            {
                path: 'wellness',
                name: 'wellness',
                component: () => import('@/views/Category.vue')
            },
            {
                path: 'category/:slug',
                name: 'category',
                component: () => import('@/views/Category.vue')
            },
            {
                path: 'gift-cards',
                name: 'giftCard',
                component: () => import('@/views/GiftCard.vue')
            },
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/Layout1.vue'),
        children: [
            {
                path: ':slug?',
                name: 'productPage',
                component: () => import('@/views/ProductView')
            }
        ]
    },
    {
        path: '/partner',
        component: () => import('@/layouts/Layout1.vue'),
        children: [
            {
                path: ':slug?',
                name: 'partnerPage',
                component: () => import('@/views/PartnerView')
            }
        ]
    },
    {
        path: '/cart',
        component: () => import('@/layouts/Layout1.vue'),
        children: [
            {
                path: '',
                name: 'cartPage',
                component: () => import('@/views/cart/CartView')
            }
        ]
    },
    {
        path: '/checkout',
        component: () => import('@/layouts/Layout1.vue'),
        children: [
            {
                path: '',
                name: 'checkoutPage',
                redirect: '/checkout/personal-data',
                component: () => import('@/views/cart/CheckoutView'),
                children: [
                    {
                        path: 'personal-data',
                        name: 'checkoutPersonalData',
                        component: () => import('@/components/CheckoutPersonalDataComponent')
                    },
                    {
                        path: 'payment',
                        name: 'checkoutPayment',
                        component: () => import('@/components/PaymentMethodComponent')
                    },
                ]
            }
        ]
    },
    {
        path: '/luggage',
        name: 'luggage',
        component: () => import('@/views/LuggageView.vue')
    },
    ...superRoutes,
    { 
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/PageNotFound.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const hasRightUserType = (to) => {
    let user = store.getters['user/getUser'];
    if(!user || !user.user_type) {
        return false
    }

    if (
        to.meta.requireUserTypes
        && !to.meta.requireUserTypes.includes(user.user_type)
    ) {
        return false
    }

    // if (
    //     to.meta.requireRoles
    //     && !to.meta.requireRoles.includes(user?.role?.role_slug)
    // ) {
    //     return false
    // }
    
    return true
}

const { isMobileSize } = useScreenSizeBehaviours()
let isBackButtonClicked = false
let backToIndex = sessionStorage.getItem('goBackFromService') ?? 0
router.beforeEach((to, from, next) => {
    if (to.matched.some(url => url.meta.requireAuth)) {
        if (!store.getters['user/getIsLoggedIn']) {
            next({ name: 'home' })
        }
    }
    if (
        to.matched.some(url => url.meta.requireUserTypes) 
        && !hasRightUserType(to)
    ) {
        next({ name: 'home' })
    }
    if (!isMobileSize.value && to.matched.some(url => url.name == 'ClientMessages') && !Number(to.params.id)) {
        let id = store.getters["chat/getDefaultChatRoom"]
        if(id) {
            next({name: 'ClientMessages', params: { id: id }})
        }
    }
    if (to.name == from.name && from.name == 'productPage') {
        if(Object.values(from.query).length > 0) {
            isBackButtonClicked = true
        }
    } else if(to.name === 'productPage') {
        if(backToIndex == 0) {
            backToIndex = router.options.history.state.position
            sessionStorage.setItem('goBackFromService', backToIndex)
        }
    }

    next()
})

router.afterEach((to, from) => {
    // Close menu on mobile. After navigation...
    if (isMobileSize.value && from.name) {
        if (!store.getters['event/getIsToggled']) {
            store.commit('event/TOGGLE_SIDEBAR')
        }
    }

    if (from.name === to.name) {
        return
    }

    window.scroll({ top: 0 })

    if (store.getters['user/getIsLoggedIn']) {
        store.dispatch('user/fetchUnreadMessages')
    }
})

window.addEventListener('popstate', () => {
    if(isBackButtonClicked) {
        isBackButtonClicked = false
        router.go(backToIndex - router.options.history.state.position)
        sessionStorage.removeItem('goBackFromService')
    }
})

export default router
