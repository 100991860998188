import axios from "axios"
import ApiManager from '@/helpers/ApiManager.js'
import { useTransferForm } from '@/composables/useTransferForm'
import { calcPrice } from '@/composables/useService'
import {useToast} from 'vue-toast-notification'

const toast = useToast()

const transferComposable = useTransferForm()
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const getDefaultState = () => ({
    items: JSON.parse(localStorage.getItem('seeCartItems')) || [],
    paymentMethod: JSON.parse(localStorage.getItem('paymentMethod')) || null,
    itemJustAdded: {},
    billingData: JSON.parse(localStorage.getItem('billingData')) || {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        country: '',
        city: '',
        address: '',
        zipcode: '',
        vatnumber: ''
    },
    billingDataForm: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        country: '',
        city: '',
        address: '',
        zipcode: '',
        vatnumber: ''
    },
    paymentFormData: JSON.parse(localStorage.getItem('paymentFormData')) || {
        customer_phone: null,
        phone: null,
        email: null,
        address: null,
        country: null,
        city: null,
        zipcode: null,
        pay_currency: null,
    },
    paymentErrors: {},
    mbwayCountdownInitialTime: sessionStorage.getItem('countdown') || null,
    mbwayCountDown: sessionStorage.getItem('countingDown') || null,
    paymentResponse: JSON.parse(sessionStorage.getItem('paymentResponse')) || null,
    errors: {},
    creditCardData: {
        email: '',
        address: '',
        country: '',
        city: '',
        zipcode: '',
    },
    checkoutFormErrors:{},
    mbWayPhoneNumber: '',
    processingPayment: false,
    notes: localStorage.getItem('checkoutNotes') || '',
    promo_code: localStorage.getItem('promoCode') || '',
    cryptoCurrencies: [],
    currencyPrice: null,
    usedPoints: localStorage.getItem('usedPoints') || null,
    authError: false,
})

const state = getDefaultState()

const getters = {
    getCartItems: state => state.items,
    cartTotal: state => {
        return state.items.reduce((a, b) => {
            let itemPrice = 0
            if (b.vehicle_id) {
                itemPrice = transferComposable.calcPrice(b, b.purchase_notes)
            } else if (b.id === -1) {
                itemPrice = b.value
            } else {
                itemPrice = calcPrice(b, b.purchase_notes)
            }
            
            if (b.discount) {
                itemPrice -= b.discount
            }

            return a += itemPrice
        }, 0).toFixed(2)
    },
    getFormErrors: state => state.errors,
    getPaymentFormErrors: state => state.paymentErrors,
    getPaymentMethod: state => state.paymentMethod,
    getAddedItem: state => state.itemJustAdded,
    getBillingDataForm: state => state.billingDataForm,
    getBillingData: state => state.billingData,
    getFormField: state => field =>  state.billingDataForm[(field)],
    getPaymentForm: state => state.paymentFormData,
    getPaymentFormField: state => field => state.paymentFormData[(field)],
    getMbwayCountDown: state => state.mbwayCountDown,
    getPaymentResponse: state => state.paymentResponse,
    getCreditCardData: state => state.creditCardData,
    getCheckoutFormErrors: state => state.checkoutFormErrors,
    getMbWayPhoneNumber: state => state.mbWayPhoneNumber,
    isProcessingPayment: state => state.processingPayment,
    getNotes: state => state.notes,
    getPromoCode: state => state.promo_code,
    getCryptoCurrencies: state => state.cryptoCurrencies,
    getCryptoCurrencyPrice: state => state.cryptoCurrencyPrice,
    getUserPoints: state => state.usedPoints,
    getAuthError: state => state.authError,
}

const actions = {
    addItemToCart: ({commit, dispatch, rootGetters}, payload) => {
        commit('ADD_ITEM', payload)
        if (rootGetters['user/getUser']) {
            dispatch('updateDatabaseCart')
        }
    },
    addPaymentMethod: ({commit}, payload) => {
        commit('ADD_PAYMENT_METHOD', payload)
    },
    removeItemFromCart: ({commit, dispatch, rootGetters}, payload) => {
        commit('REMOVE_ITEM', payload)
        if (rootGetters['user/getUser']) {
            dispatch('updateDatabaseCart')
        }
    },
    updateDatabaseCart: async ({getters, commit}) => {
        let postAttempt = await ApiManager.post('/api/cart', { items: getters['getCartItems'], 'total_price': getters['cartTotal'] })
        if (postAttempt) {
            if (postAttempt.data?.content?.items) {
                commit('OVERWRITE_ITEMS', postAttempt.data.content.items)
            }
        }
    },
    processPayment: ({commit, getters, state}) => {
        commit('SET_CHECKOUT_FORM_ERRORS', {})
        commit('SET_PROCESING_PAYMENT', true)
        return new Promise((resolve, reject) => {
            let payload = {
                payment_method: getters['getPaymentMethod'].method,
                promo_code: getters['getPromoCode'],
                billingData: getters['getBillingDataForm'],
                ...getters['getBillingData'],
                ...getters['getPaymentForm']
            }
            payload.card_number = payload.card_number?.replace(/[\D\s]/g, '')
            payload.customer_phone = `351#${payload.customer_phone?.replace(/[\D\s]/g, '')}`

            payload.notes = state.notes

            ApiManager.post('api/bookings', payload, null, 'Operation could not be completed. Please try again or change your payment method.').then(response => {
                if(response.errors) {
                    toast.error("Something went wrong", { position: 'top-right'})
                    commit('SET_CHECKOUT_FORM_ERRORS', response.errors)
                    commit("SET_PAYMENT_ERRORS", response.errors)
                    commit('SET_PROCESING_PAYMENT', false)
                    return reject(response)
                }

                if(response.data && !response.data.status) {
                    toast.error("Operation could not be completed. Please try again or change your payment method.", { position: 'top-right'})
                    commit('SET_PROCESING_PAYMENT', false)
                    return reject(response)
                }
                
                if (! ['CARD', 'PAYPAL'].includes(payload.payment_method)) {
                    commit('RESET_CART')
                }

                commit('SET_PAYMENT_RESPONSE', response.data)

                if (payload.payment_method !== 'CARD') {
                    commit('SET_PROCESING_PAYMENT', false)
                }
                
                commit('SET_PROMO_CODE', '')

                return resolve(response)
            })
        })
    },
    clearItemJustAdded : ({ state }) => {
        state.itemJustAdded = {}
    },
    setCheckoutFormData: ({ commit }, payload) => {
        commit('RESET_FIELD_ERROR', payload)
        commit('SET_CHECKOUT_FORM_DATA', payload)
    },
    saveCheckoutForm: ({commit}, payload) => {
        commit('SAVE_BILLING_DATA', payload)
    },
    setInitialBillingData: ({ commit}, payload) => {
        commit('SET_INITIAL_BILLING_DATA', payload)
    },
    setPaymentFormData: ({ commit }, payload) => {
        commit('RESET_PAYMENT_FIELD_ERROR', payload)
        commit('SET_PAYMENT_FORM_DATA', payload)
    },
    deleteCart: async ({commit}) => {
        await ApiManager.post("/api/close-cart")
        commit('RESET_CART')
    },
    applyPromoCode: async ({commit}, code) => {
        let response = await ApiManager.post('/api/apply-promo-code', {code})
        if(response?.data?.status) {
            commit('SET_PROMO_CODE', code)
            commit('OVERWRITE_ITEMS', response.data.content.items)
        }
        return response
    },
    removePromoCode: async ({ commit }) => {
        let response = await ApiManager.post('/api/remove-promo-code')
        if (response?.data?.status) {
            commit('REMOVE_PROMO_CODE')
            commit('OVERWRITE_ITEMS', response.data.content.items)
        }
        return response
    },
    applyPoints: async ({commit}, points) => {
        let response = await ApiManager.post('/api/apply-points', { points })
        if (response?.data?.status) {
            commit('SET_USED_POINTS', points)
            commit('OVERWRITE_ITEMS', response.data.content.items)
        }
        return response
    },
    removePoints: async ({ commit }) => {
        let response = await ApiManager.post('/api/remove-points')
        if (response?.data?.status) {
            commit('REMOVE_USED_POINTS')
            commit('OVERWRITE_ITEMS', response.data.content.items)
        }
        return response
    },
    fetchCryptoCurrencies: async ({ commit }) => {
        let response = await ApiManager.get('/api/payments/crypto-currencies')
        if (response?.data?.currencies) {
            commit('SET_CRYPTO_CURRENCIES', response?.data?.currencies)
        }
        return response
    },
    fetchCryptoCurrencyPrice: async ({ commit, getters }) => {
        let response = await ApiManager.post('/api/payments/crypto-currency-price', {
            amount: getters['cartTotal'],
            currency_to: getters['getPaymentForm'].pay_currency,
        })

        if (response?.data) {
            commit('SET_CRYPTO_CURRENCY_PRICE', response?.data)
        }
        return response
    }
}

const mutations = {
    OVERWRITE_ITEMS: (state, items) => {
        items = typeof items === "string"
            ? JSON.parse(items)
            : items

        state.items = items
        localStorage.setItem('seeCartItems', JSON.stringify(state.items))
    },
    ADD_ITEM: (state, payload) => {
        const isUpdatedItem = state.items.find(item => item.id === payload.id)
        state.items = state.items.filter(item => item.id !== payload.id)
        state.items.push(payload)
        
        if (! isUpdatedItem) {
            state.itemJustAdded = structuredClone(payload)
        }

        localStorage.setItem('seeCartItems', JSON.stringify(state.items))
    },
    REMOVE_ITEM: (state, id) => {
        state.items = state.items.filter(item => item.id !== id)
        state.itemJustAdded = {}
        localStorage.setItem('seeCartItems', JSON.stringify(state.items))
    },
    ADD_PAYMENT_METHOD: (state, payload) => {
        if(!payload) {
            state.paymentMethod = null
            localStorage.removeItem('paymentMethod')
        } else {
            state.paymentMethod = payload
            localStorage.setItem('paymentMethod', JSON.stringify(state.paymentMethod))
        }
    },
    RESET_CART: (state) => {
        localStorage.removeItem('checkoutNotes')
        state.notes = ''
        localStorage.removeItem('seeCartItems')
        localStorage.removeItem('paymentMethod')
        Object.assign(state, getDefaultState())
    },
    SET_CHECKOUT_FORM_DATA: (state, formData) => {
        state.billingDataForm = {
            ...state.billingDataForm,
            ...formData
        }
    },
    RESET_FIELD_ERROR: (state, field) => {
        let [key] = Object.keys(field)
        if(Object.keys(state.errors).includes(key))
            delete state.errors[key]
    },
    RESET_PAYMENT_FIELD_ERROR: (state, field) => {
        let [key] = Object.keys(field)
        if(Object.keys(state.paymentErrors).includes(key))
            delete state.paymentErrors[key]
    },
    SET_PAYMENT_ERRORS: (state, errors) => {
        Object.entries(errors).map(error => {
            const [key, value]  = error
            const keyPieces     = key.split('.')

            if(keyPieces[1] && !isNaN(Number(keyPieces[1]))) {
                if(!errors[keyPieces[0]]) {
                    errors[keyPieces[0]] = []
                }
                errors[keyPieces[0]].push(String(value).replace(key, keyPieces[0]))
            }
        })
        state.paymentErrors = errors
    },
    SAVE_BILLING_DATA: (state, data)  => {
        state.billingData = {...data}
        localStorage.setItem('billingData', JSON.stringify(state.billingData))
        let phone = data.phone.replace(/[\D\s]/g, '')
        state.paymentFormData = {
            ...state.paymentFormData,
            customer_phone: phone ? `${phone.substr(-9, 3)} ${phone.substr(-6, 3)} ${phone.substr(-3)}`: null,
            phone: phone,
            email: data.email ?? null,
            country: data.country ?? null,
            city: data.city ?? null,
            address: data.address ?? null,
            zipcode: data.zipcode ?? null,
            vatnumber: data.vatnumber ?? null
        }
        localStorage.setItem('paymentFormData', JSON.stringify(state.paymentFormData))
    },
    SET_INITIAL_BILLING_DATA: (state, data) => {
        state.billingData = {
            first_name: data.first_name ?? '',
            last_name: data.last_name ?? '',
            phone: data.phone ?? '',
            email: data.email ?? '',
            country: data?.profile ? data.profile.country ?? '' : data.country ?? '',
            city: data?.profile ? data.profile.city ?? '' : data.city ?? '',
            address: data?.profile ? data.profile.address ?? '' : data.address ?? '',
            zipcode: data?.profile ? data.profile.zipcode ?? '' : data.zipcode ?? '',
            vatnumber: data?.profile ? data.profile.vatnumber ?? '' : data.vatnumber ?? '',
        }
        state.billingDataForm = state.billingData
        localStorage.setItem('billingData', JSON.stringify(state.billingData))

        let phone = data.phone ? data.phone.replace(/[\D\s]/g, '') : null
        state.paymentFormData = {
            ...state.paymentFormData, 
            customer_phone: phone ? `${phone.substr(-9, 3)} ${phone.substr(-6, 3)} ${phone.substr(-3)}`: null,
            phone: phone,
            email: data.email ?? null,
            country: data.country ?? null,
            city: data.city ?? null,
        }
        localStorage.setItem('paymentFormData', JSON.stringify(state.paymentFormData))
    },
    SET_PAYMENT_FORM_DATA: (state, formData) => {
        state.paymentFormData = {
            ...state.paymentFormData,
            ...formData
        }
    },
    START_COUNTDOWN: state => {
        if(!state.mbwayCountdownInitialTime) {
            state.mbwayCountdownInitialTime = new Date().getTime() / 1000
            state.mbwayCountDown = 300
            sessionStorage.setItem('countdown', state.mbwayCountdownInitialTime)
            sessionStorage.setItem('countingDown', state.mbwayCountDown)
        }
    },
    COUNTDOWN: state => {
        let currentTime = new Date().getTime() / 1000
        let elapsedTime = Math.round(currentTime - state.mbwayCountdownInitialTime)
        if(elapsedTime <= 0) {
            state.mbwayCountdownInitialTime = null
            state.mbwayCountDown = null
        } else {
            state.mbwayCountDown = Math.max(0, 300 - elapsedTime)
            sessionStorage.setItem('countingDown', state.mbwayCountDown)
        }
    },
    CLEAR_COWNTDOWN: state => {
        state.mbwayCountdownInitialTime = null
        state.mbwayCountDown = null 
        sessionStorage.removeItem('countdown')
        sessionStorage.removeItem('countingDown')
    },
    SET_PAYMENT_RESPONSE: (state, data) => {
        if(!data) {
            state.paymentResponse = null
            sessionStorage.removeItem('paymentResponse')
        } else {
            state.paymentResponse = data.content
            sessionStorage.setItem('paymentResponse', JSON.stringify(state.paymentResponse))
        }
    },
    SET_CREDIT_CARD_DATA: (state, data) => {
        state.creditCardData = data
    },
    SET_CHECKOUT_FORM_ERRORS: (state, errors) => {
        state.checkoutFormErrors = errors
    },
    SET_MB_WAY_PHONE_NUMBER: (state, phoneNumber) => {
        state.mbWayPhoneNumber = phoneNumber
    },
    SET_PROCESING_PAYMENT: (state, status) => {
        state.processingPayment = status
    },
    SET_NOTES: (state, notes) => {
        localStorage.setItem('checkoutNotes', notes)
        state.notes = notes
    },
    SET_PROMO_CODE: (state, code) => {
        state.promo_code = code
        localStorage.setItem('promoCode', code)
    },
    REMOVE_PROMO_CODE: (state) => {
        state.promo_code = null
        localStorage.removeItem('promoCode')
    },
    SET_USED_POINTS: (state, points) => {
        state.usedPoints = points
        localStorage.setItem('usedPoints', points)
    },
    REMOVE_USED_POINTS: (state) => {
        state.usedPoints = null
        localStorage.removeItem('usedPoints')
    },
    SET_CRYPTO_CURRENCIES: (state, currencies) => {
        state.cryptoCurrencies = currencies
    },
    SET_CRYPTO_CURRENCY_PRICE: (state, price) => {
        state.cryptoCurrencyPrice = price
    },
    SET_AUTH_ERROR: (state, value) => {
        state.authError = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}